import { on_ready, is_elementor_edit, hover_intent } from './js/utils';
import SaveItems from './classes/SaveItems';

// load global styles
import './main.scss';

import 'components/forms/forms';
import 'components/header/header';
import 'components/carousel/carousel';
import 'components/toggle_height';
import 'components/footer/sticky_footer_cta';
import 'components/accordions/accordions';
import retina from './vendors/retina/retina';

on_ready(()=>{

    if( is_elementor_edit() ) return;
    dynamic_imports();
    
    hover_intent_init();
    init_retina();
    initTabScrollFix();

    preventAutoLinking();

    // Wishlist
    new SaveItems({
        key: 'wishlist',
        total_count_element: '.wishlist_count',
        add_item_button: '.wishlist_add',
    });

    // Compare
    new SaveItems({
        key: 'compare',
        total_count_element: '.compare_count',
        add_item_button: '.compare_add',
        limit: 3,
        limit_message: 'You can only add up to 3 items to compare',
    });

    setTimeout(()=>check_elements_scrollbar(), 200);

});

function dynamic_imports(){
    // slide menu
    if( document.querySelector('.ff_slide_menu') ) {
        import('components/slide_menu/slide_menu');
    }
    // search popup
    if( document.querySelector('.search_popup') ) {
        import('components/search_popup/search_popup');
    }
}

function hover_intent_init(){
    document.querySelectorAll('.hover_intent').forEach(el=>{
        hover_intent(el);
    });
}

// add class to elements with scrollbar - for extra padding styling
function check_elements_scrollbar(){
    document.querySelectorAll('.check_scrollbar').forEach(element=>{
        if( element.scrollHeight > element.clientHeight ) {
            element.classList.add('with_scrollbar');
        }
    })
}

function init_retina(){

    if( typeof retina === 'undefined' ) return;
    if( window.devicePixelRatio < 2) return;

    let items = document.querySelectorAll('.retina');
    if( !items ) return;

    items.forEach(item=>{
        if( item.nodeName == 'IMG' ) {
            item.dataset.rjs = 2;
        } else {
            // add to children
            item.querySelectorAll('img').forEach(img=>{
                img.dataset.rjs = 2;
            })
        }
    })

    retina();
}

// Tabs iOS fix 
function initTabScrollFix() {
    if (window.innerWidth > 768) return;

    function scrollToTabContainer(tabContainer) {
        const tabContainerTop = tabContainer.getBoundingClientRect().top + window.pageYOffset - 10;
        window.scrollTo(0, tabContainerTop);
    }
    document.querySelectorAll('.e-n-tabs').forEach(tabContainer => {
        tabContainer.querySelectorAll('.e-n-tab-title').forEach(tab => {
            tab.addEventListener('click', () => {
                setTimeout(() => scrollToTabContainer(tabContainer), 100);
            });
        });
    });
}

// Prevent Safari from auto-linking ABN/ACN numbers
function preventAutoLinking() {
    document.querySelectorAll('.no-auto-link').forEach(span => {
        const links = span.querySelectorAll('a');
        links.forEach(link => {
            link.outerHTML = link.innerHTML; 
        });
    });
}